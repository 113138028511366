.add-document-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 92vh;
}

.add-document-container {
  display: flex;
  flex-direction: column;
  padding: 15px 30px;
  background: #fff;
  margin: 10px;
  min-width: 60%;
  border-radius: 12px;
  border: 2px solid #007bffbf;
  max-height: 650px;
  overflow-y: auto;
}

.add-document-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.add-document-title-with-img {
  display: flex;
  align-items: center;
  margin-left: -14px;
  margin-bottom: 30px;
}

.add-document-title {
  font-size: 1.5rem;
  font-weight: 600;
  margin: 15px 0px;
  color: #007bff;
}

.add-document-inputs {
  display: flex;
  /* align-items: center; */
  outline: none;
  border: none;
  justify-content: space-between;
  width: 100%;
}

.select-ui label {
  padding-top: 12px;
}

.add-document-inputs-with-error-message {
  display: flex;
  flex-direction: column;
  width: 49%;
}

.request-list-link {
  cursor: pointer;
  font-size: 18px;
  color: #007bff;
  margin-top: 23px;
  margin-left: 8px;
  text-decoration: underline;
}

/* .add-document-inputs-with-error-message .MuiOutlinedInput-root {
  min-height: 40px;
  max-height: 40px;
  min-width: 100%;
  max-width: 100%;
  letter-spacing: 0.02rem;
  background-color: #ffffff9a;
  color: #000000d8;
}

.add-document-inputs-with-error-message .MuiOutlinedInput-root svg {
  width: 35px;
}

.add-document-inputs-with-error-message .MuiOutlinedInput-input {
  margin-left: 4px;
}

.add-document-inputs-with-error-message label {
  margin-top: -8px;
  letter-spacing: 0.02rem;
  font-size: 0.9rem;
  font-weight: 500;
  color: #000000c6;
}

.add-document-inputs-with-error-message .Mui-focused {
  margin-top: 0px;
}

.add-document-inputs-with-error-message .MuiFormLabel-filled {
  margin-top: 0px;
} */

.doc-header-btn {
  display: flex;
  gap: 10px;
}

.add-doc-error-message {
  color: red;
  margin-top: -4px;
  margin-bottom: 5px;
  font-size: 0.8rem;
}

.add_document_Btn {
  width: 86%;
  padding: 1rem;
}

.add-document-header button {
  background: none;
  color: #007bff;
  font-size: 28px;
  box-shadow: none;
  max-height: 25px;
}

.add-document-header button:hover {
  background-color: rgb(245, 243, 243);
  box-shadow: none;
}

.add-document-inputs-with-error-message fieldset {
  border: 1px solid #ccc;
}

.add-document-inputs-with-error-message fieldset:focus-within,
.add-document-inputs-with-error-message fieldset:focus-visible,
.add-document-inputs-with-error-message input:focus-visible,
.add-document-inputs-with-error-message input:focus-within .add-document-inputs-with-error-message input:hover,
.add-document-inputs-with-error-message fieldset:hover {
  outline: none;
  border: none;
}

.add-document-button,
.add-user-button,
.add-category-button {
  text-align: center;
}

.MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input {
  padding-right: 0 !important;
}
